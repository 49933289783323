import React, { useEffect  } from 'react';
import { BrowserRouter, Switch, Route, Link, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import logo from './images/logo.png';
import './App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import NoMatchScreen from './screens/NoMatch/NoMatchScreen';
import HomeScreen from './screens/Home/HomeScreen';
import TermsSalesScreen from './screens/Misc/TermsSalesScreen';
import LegalNoticeScreen from './screens/Misc/LegalNoticeScreen';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function App() {
  
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <ScrollToTop>
        <header className="nav-wrapper">
          <nav className="nav">
            <Link className="logo-link" to="/">
              <img src={logo} className="logo" id="logo" alt="logo de flawless beauty center" />
            </Link>
          </nav>
        </header>
        <main className="main">
          <Switch>
            <Route path="/" exact>
              <HomeScreen></HomeScreen>
            </Route>  
            <Route path="/legal-notice" exact>
              <LegalNoticeScreen></LegalNoticeScreen>
            </Route>  
            <Route path="/terms-of-sales" exact>
              <TermsSalesScreen></TermsSalesScreen>
            </Route>           
            <Route path="*">
              <NoMatchScreen></NoMatchScreen>
            </Route>
          </Switch>
        </main>
        <footer className="footer">
          <div className="info">
            <div className="footerLink">
                <h5>{t('footer.about')}</h5>
                <ul>
                  <li><Link to="/legal-notice">{t('footer.legal-notice')}</Link></li>
                  <li><Link to="/terms-of-sales">{t('footer.terms-sales')}</Link></li>
                  {/* <li><Link to="/privacy-data">{t('footer.privacy-data')}</Link></li> */}
                </ul>
            </div>
            <div className="footerLink">
                <h5>{t('footer.join-us')}</h5>
                <ul className="horizontal">
                  <li><a href="https://www.facebook.com/flawless.beautycenter/" target="_blank" rel="noopener nofollow noreferrer"><FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon></a></li>
                  <li><a href="https://www.instagram.com/flawless.beautycenter/" target="_blank" rel="noopener nofollow noreferrer"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a></li>
                </ul>
            </div>
          </div>
          <div className="copyright">
            <p>© flawless beauty center {new Date().getFullYear()}, {t('footer.all-right-reserved')}</p>
            <p>{t('footer.site-designed-by')} <a id="madras-link" href="https://www.madras-technologies.com/" target="_blank" rel="noopener noreferrer">madras technologies</a></p>
          </div>
        </footer>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;