import React from 'react';
import { useTranslation } from "react-i18next";
import logo from './images/logo.png';
import './Maintenance.scss';

function Maintenance() {

    const { t } = useTranslation();

    return (
        <div className="maintenance">
          <header className="maintenance-header">
            <img src={logo} className="logo" alt="logo" />
            <p>{t('maintenance.text')}</p>
          </header>
        </div>
    );
}

export default Maintenance;