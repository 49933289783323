import React from 'react';
import { useTranslation } from "react-i18next";
import './MiscScreen.scss';

function LegalNoticeScreen() {
    
    const { t } = useTranslation();

    return (
        <div className="misc-container">

            <h1>{t('footer.legal-notice')}</h1>   

            <h4>SASU FLAWLESS BEAUTY CENTER</h4> 
            <h4>Siret : 887 805 570 00017 - APE : 4791A</h4> 
            <h4>Siège social : 2 rue Coysevox, Bureau 3 - 69001 LYON</h4> 
            <h4>Contact : +33672349670 - centerflawlessbeauty@gmail.com</h4> 

        </div>);    
}

export default LegalNoticeScreen;