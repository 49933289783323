import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { LANGUAGE_FR } from './constants/languageConstants';
import translationEN from './i18n/en.json';
import translationFR from './i18n/fr.json';
 
let defaultLanguage = LANGUAGE_FR;
 
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
};
 
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLanguage, 
    keySeparator: ".", 
    interpolation: {
      escapeValue: false, 
      format: (value, rawFormat, lng) => {
        const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim());
        if(format === 'price') {
            return Intl.NumberFormat(lng, {
              style: 'currency',
              currency: additionalValues[0]
            }).format(value);
        }
      }
    }
  });
 
  export default i18n;