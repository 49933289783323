import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import './NoMatchScreen.scss';

function NoMatchScreen() {
    
    const { t } = useTranslation();

    return (
    <div className="not-found-box">
        <h1>{t('nomatch.title')}</h1>
        <h2>{t('nomatch.text')}</h2>        
        <Link to="/">{t('nomatch.go-home')}</Link>
    </div>);    
}

export default NoMatchScreen;