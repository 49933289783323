import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Maintenance from './Maintenance';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { makeServer } from "./server"

if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development", baseUrl: process.env.REACT_APP_BACKEND_API_URL })
}

function MainPage(props) {
  const isMaintenance = props.isMaintenance;
  if (isMaintenance) {
    return <Maintenance />;
  }
  return <App />;
}

ReactDOM.render(
  <MainPage isMaintenance={false}/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
