import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../images/logo_official.png';
import intro_mp4 from '../../videos/intro.mp4';
import intro_ogv from '../../videos/intro.ogv';
import intro_webm from '../../videos/intro.webm';
import './HomeScreen.scss';

function HomeScreen() {
        
    const { t } = useTranslation();

    const Intro = () => (
        <>        
            <div className="intro-top">
                <h4>{t('home.intro1-text')}</h4> 
                <h4>{t('home.intro2-text')}</h4>
            </div>
            <div className="intro-bottom">
                <h4>{t('home.visit-text')}</h4> 
                <a href="https://lynk.bio/flawlessbeautycenter" rel="noopener nofollow noreferrer">{t('home.visit-link-text')}</a>
            </div>
        </>
    );

    return (
        <>
            <section className="widget top">
                <div className="container">
                    <div className="visit xs">
                        <Intro></Intro>
                    </div>
                    <div className="top-image">
                        <img src={logo} className="logo" id="logo" alt="logo de flawless beauty center" />
                        <div className="visit">
                            <Intro></Intro>
                        </div>
                    </div>                
                    <div className="top-text">
                        <video autoPlay loop muted playsInline>
                            <source src={intro_webm} type="video/webm"></source>
                            <source src={intro_mp4} type="video/mp4"></source>
                            <source src={intro_ogv} type="video/ogg"></source>
                        </video>
                    </div>
                </div>
            </section>
        </>
    );
}
export default HomeScreen;